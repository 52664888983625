import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Channel, WaTemplateResponse } from 'lib-trend-core';

export interface TemplateMessage {
  previewText: string;
  params: string[];
  imageFile: any;
}

@Component({
  selector: 'app-campaign-template-list',
  templateUrl: './campaign-template-list.component.html',
  styleUrl: './campaign-template-list.component.scss'
})
export class CampaignTemplateListComponent extends AbstractComponent implements OnInit {

  selectedChannel: Channel;

  constructor(
    public injector: Injector,
    public dialogRef: MatDialogRef<CampaignTemplateListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Channel) {
    super(injector);
    this.selectedChannel = data;
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

  onSelectTemplate(template: Partial<WaTemplateResponse>) {
    this.dialogRef.close(template)
  }

}
