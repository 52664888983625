import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TrendCloudAPIService } from '@trendbuild/trend-cloud-api';
import { AttendanceService, CampaignService, ChannelService, ContactEditComponent, ContactService, FirebaseService, MessageService } from 'lib-trend-core';
import { interval, Subscription } from 'rxjs';
import { WaTemplateMessageComponent } from '../../wa-template/wa-template-message/wa-template-message.component';

export interface TemplateMessage {
  previewText: string;
  params: []
}

@Component({
  selector: 'campaign-wa-template-message',
  templateUrl: './campaign-wa-template-message.component.html',
  styleUrls: ['./campaign-wa-template-message.component.scss']
})
export class CampaignWaTemplateMessageComponent extends WaTemplateMessageComponent implements OnInit, OnDestroy {

  readOnlyInputVariable = true;

  currentTime: string = '';
  private timeSubscription: Subscription;

  constructor(
    injector: Injector,
    contactService: ContactService,
    channelService: ChannelService,
    messageService: MessageService,
    attendanceService: AttendanceService,
    trendCloudAPIService: TrendCloudAPIService,
    firebaseService: FirebaseService,

    public campaignService: CampaignService) {

    super(injector, contactService, channelService, messageService,
      attendanceService, trendCloudAPIService, firebaseService);
  }

  override ngOnInit() {
    this.timeSubscription = interval(1000).subscribe(() => {
      const now = new Date();
      this.currentTime = now.toLocaleTimeString();
    });
  }

  override ngOnDestroy() {
    if (this.timeSubscription) {
      this.timeSubscription.unsubscribe();
    }
  }

  back() {
    history.back();
  }

  async save() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const campaign = { ...this.formGroup.value }
    await this.campaignService.create(campaign).subscribe({
      next: (value) => this.alertService.success(),
      error: (err) => this.alertService.error(err.error.message),
      complete: () => this.back(),
    });
  }
}
